import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

@Component({
  selector: 'prime-platform-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'DEPEMOKIMAB';
  titleClass = 'uppercase';
  logoSrc = './assets/images/client-logo.svg';
  logoClass = 'h-4 sm:h-8 w-auto';
  headerButtonOptions = [
    {
      iconSrc: 'assets/icons/icon-notifications.svg',
      label: 'Alerts',
      route: 'alerts',
    },
    {
      iconSrc: 'assets/icons/icon-userguide.svg',
      label: 'User Guide',
      route: '',
    }
  ];

  loading = false;
  constructor(public router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      }
      if (
        ev instanceof NavigationEnd ||
        ev instanceof NavigationCancel ||
        ev instanceof NavigationError
      ) {
        this.loading = false;
      }
    });
  }
}
