import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionTileComponent } from '../section-tile/section-tile.component';
import { ActivatedRoute } from '@angular/router';
import { View} from '../../models';

@Component({
  selector: 'prime-bucket-overview',
  standalone: true,
  imports: [CommonModule, SectionTileComponent],
  templateUrl: './bucket-overview.component.html',
  styleUrls: ['./bucket-overview.component.scss'],
})
export class BucketOverviewComponent implements OnInit {
  bucket: View | null = null;
  parentRoute = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.bucket = this.route.snapshot.data['bucket'];
    this.parentRoute = this.route.snapshot.data['parentRoute'];
  }

  getRoute(section: View) {
    return `${this.parentRoute}/${section.slug}/${section.id}`;
  }
}
