import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonModule} from '@angular/common';
import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {FavoriteService, FileService} from '@prime-platform/shared';
import {take} from 'rxjs';
import {Reference} from '../../../models';
import {FavoriteButtonComponent} from '../../favorite-button/favorite-button.component';
import {ShareButtonComponent} from '../../share-button/share-button.component';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'prime-reference-modal-sidebar',
  standalone: true,
  imports: [CommonModule, ShareButtonComponent, FavoriteButtonComponent, FormsModule],
  templateUrl: './reference-modal-sidebar.component.html',
  styleUrls: ['./reference-modal-sidebar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  animations: [
    trigger('toggleAnimation', [
      state('false', style({ transform: 'translateX(100%)' })),
      state('true', style({ transform: 'translateX(0)' })),
      transition('* <=> *', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ReferenceModalSidebarComponent implements OnInit {
  @Input() reference: Reference | null = null;
  @Input() showClean = true;
  @Output() tagClicked = new EventEmitter<string>();
  @Output() subtagClicked = new EventEmitter<string>();
  @Output() showCleanToggle = new EventEmitter<boolean>();
  isFavourite = false;
  constructor(private fileService: FileService,
              private favoriteService: FavoriteService) {
  }

  ngOnInit() {
    this.isFavourite = this.reference
      ? this.reference.isFavorite || false
      : false;
  }

  onTagClick(id: string) {
    this.tagClicked.emit(id);
  }

  onSubtagClick(id: string) {
    this.subtagClicked.emit(id);
  }

  toggleFavourites(isFavorite: boolean, id: string) {
    const method = isFavorite ? this.favoriteService.addToFavorites(id) : this.favoriteService.removeFormFavorites(id);
    method.pipe(take(1)).subscribe(() => {
      this.isFavourite = isFavorite;
    })

  }

  download(url: string) {
    const fileName = this.reference?.displayName || '';
    this.fileService.downloadFile(url, fileName)
  }
}
