import {DialogModule} from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {BreadcrumbsComponent} from '@prime-platform/breadcrumbs';
import {HeaderComponent} from '@prime-platform/header';
import {LoadingComponent} from '@prime-platform/shared';
import { LayoutComponent } from './components/layout/layout.component';

@NgModule({
  imports: [CommonModule, BreadcrumbsComponent, HeaderComponent, LoadingComponent, RouterOutlet, DialogModule],
  declarations: [LayoutComponent],
  exports: [LayoutComponent]
})
export class LayoutModule {}
