import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

// needs sticky element to have top: -1px
// solution based on https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/

@Directive({
  selector: '[primeSticky]',
  standalone: true,
})
export class StickyDirective {
  @Input() toggledClass = 'pinned';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const element = this.el.nativeElement;
    const observer = new IntersectionObserver(
      ([e]) =>
        e.target.classList.toggle(this.toggledClass, e.intersectionRatio === 1),
      { threshold: [1] }
    );

    observer.observe(element);
  }
}
