<div
  *ngIf="result"
  (click)="onResultClicked()"
  class="group button-base flex-col md:flex-row !items-start justify-between cursor-pointer space-y-4 md:space-y-0 md:space-x-8 mt-3 px-4 py-3"
>
  <div class="grow">
    <div class="flex items-center mb-3">
      <img
        [src]="'./assets/icons/' + result.icon"
        [alt]="result.type + 'logo'"
        class="mr-3 w-4 h-4"
      />
      <div
        class="uppercase flex flex-col md:flex-row flex-wrap text-es tracking-wide text-gray-650 group-active:text-gray-150"
      >
        <div class="">
          {{ result.type }}
          <ng-container *ngIf="result.breadCrumbs && result.breadCrumbs.length">
            from:
          </ng-container>
        </div>
        <div
          class="md:pl-2 flex items-center whitespace-nowrap"
          *ngFor="let breadcrumb of result.breadCrumbs; let last = last"
        >
          <a
            (click)="onBreadCrumbClicked(breadcrumb.route)"
            class="text-brand-accent group-active:text-brand-bright"
          >
            {{ breadcrumb.label }}
          </a>
          <span
            class="md:pl-2 text-brand-accent group-active:text-brand-bright"
            *ngIf="!last"
          >
            &nbsp;&gt;&nbsp;
          </span>
        </div>
      </div>
    </div>
    <div class="md:pl-7">
      <p
        *ngIf="result.type !== 'statement'"
        class="text-md leading-none mb-1.5"
      >
        {{ result.title }}
      </p>
      <p
        *ngIf="result.type === 'statement'"
        class="flex flex-row leading-none mb-1.5"
      >
        <span class="mr-1 text-brand-accent group-active:text-brand-bright">{{
          result.title
        }}</span>
        <span [innerHTML]="result.citation"></span>
      </p>
      <p
        *ngIf="result.uniqueId && result.citation"
        class="flex flex-row text-rd font-light"
      >
        <span class="text-brand-accent group-active:text-brand-bright"
          >{{ result.uniqueId }}.&nbsp;</span
        ><span [innerHTML]="result.citation"></span>
      </p>
    </div>
  </div>

  <div
    class="flex space-x-4 justify-between w-full md:w-auto md:max-w-[140px] md:block md:text-right text-es font-semibold leading-none tracking-wide uppercase text-gray-450 group-active:text-gray-400 md:pt-0.5 lg:pt-1"
  >
    <p *ngIf="result.publishedDate" class="block mb-2.5">
      Published&nbsp;<span class="text-gray-700 group-active:text-gray-150">{{
        result.publishedDate | date : 'MM/dd/yyyy'
      }}</span>
    </p>
    <p *ngIf="result.matchCount" class="block text-right">
      <span class="text-gray-700 group-active:text-gray-150">{{
        result.matchCount
      }}</span
      >&nbsp;matches of search query
    </p>
  </div>
</div>
