import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { EnvironmentModel, UserService } from '@prime-platform/shared';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import {Alert} from '../models';


@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public alerts$ = new BehaviorSubject<Alert[]>([]);

  constructor(private http: HttpClient,
              @Inject('environment') private environment: EnvironmentModel,
              private  userService: UserService
  ) { }

  get alerts() {
    if(!this.alerts$.getValue().length){
      return this.getAlerts();
    }
    return this.alerts$.asObservable();
  }


  getAlerts() {
    return this.userService.currentUser.pipe(
      switchMap(user => {
        return this.http.get<Alert[]>(`${this.environment.apiUrl}/alert/user/${user.id}`).pipe(
          tap(res => this.alerts$.next(res))
        )
      })
    )

  }

  getReadAlerts() {
      return this.alerts.pipe(
        map(alerts => alerts.filter(a => a.read))
      )
  }

  getUnReadAlerts() {
    return this.alerts.pipe(
      map(alerts => alerts.filter(a => !a.read))
    )
  }

  private updateAlertStatus(alertId: string, statusUpdate: Partial<Alert>) {
    return this.userService.currentUser.pipe(
      switchMap(user => this.http.put(`${this.environment.apiUrl}/alert/${alertId}/user/${user.id}`, statusUpdate)),
      tap((res) => {
        const alerts = this.alerts$.getValue();
        if (alerts && res) {
          const updatedAlerts = alerts.map(alert =>
            alert.id === alertId ? { ...alert, ...statusUpdate } : alert
          );
          this.alerts$.next(updatedAlerts);
        }
      })
    );
  }

  markAsRead(alertId: string) {
    return this.updateAlertStatus(alertId, { read: true });
  }

  markAsDismissed(alertId: string) {
    return this.updateAlertStatus(alertId, { dismissed: true });
  }
}
