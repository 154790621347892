import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, of } from 'rxjs';
import { ViewService } from '../services';
import { View} from '../models';

export const viewResolver: ResolveFn<View | null> = (route, state) => {
  const id = route.paramMap.get('id');

  if (id) {
    return inject(ViewService)
      .getSection(id)
      .pipe(
        catchError((e) => {
          console.log('error', e);
          return of(null);
        })
      );
  } else {
    inject(Router).navigate(['/not-found']);
    return of(null);
  }
};
