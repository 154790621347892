import {SubTag, Tag} from './tag.model';
import {AnnotatedFile, File, FileSource} from './file.model'

export interface Reference {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  citation: string;
  displayName: string;
  doi: string;
  publicationDate: Date;
  shortCitation: string;
  showAnnotatedFile: boolean;
  uniqueID: number;
  annotatedFileId: string;
  annotatedFile: AnnotatedFile;
  annotatedFileUrl: string;
  fileId: string;
  file: File;
  fileUrl: string;
  sourceFileId: string;
  sourceFile: FileSource;
  fileSourceUrl: string;
  tags: Tag[]
  subtags: SubTag[]
  isFavorite: boolean
}
