import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, tap} from 'rxjs';
import {EnvironmentModel, Resource} from '../models';


@Injectable({
  providedIn: 'root',
})
export class ResourceService {

  constructor(private http: HttpClient,
              @Inject('environment') private environment: EnvironmentModel) { }

  getResource(id: string): Observable<Resource> {
    return this.http.get<Resource>(`${this.environment.apiUrl}/resource/${id}`)
  }
}
