<div>
  <div
    class="w-full h-0.5 bg-gradient-to-r from-brand-primary to-brand-secondary"
  ></div>
  <div
    class="w-full flex flex-wrap md:flex-no-wrap md:space-x-0 items-center justify-between px-1 pb-0 pt-1 md:py-1 rounded-b bg-gradient-to-b from-black/5 to-white/10"
  >
    <div class="flex space-x-1 w-1/2 md:w-auto pr-1 md:pr-0">
      <prime-toolbar-button *ngFor="let button of headerButtonOptions"
                            [iconSrc]=button.iconSrc
                            [label]=button.label
                            [route]=button.route
      ></prime-toolbar-button>
    </div>
    <div
      class="order-3 md:order-2 flex flex-grow justify-center md:px-2 py-1 md:py-0"
    >
      <button
        class="button-base group flex justify-center w-full md:max-w-sm h-8 space-x-3 px-2 md:px-4 py-2 sm:py-2.5 !rounded-full hover:rounded-full active:rounded-full"
        (click)="openSearch()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 fill-none stroke-gray-500 group-hover:stroke-brand-primary group-active:stroke-gray-100 transition-all"
          viewBox="0 0 512 512"
        >
          <path
            d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
            stroke-miterlimit="10"
            stroke-width="44"
          />
          <path
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="44"
            d="M338.29 338.29L448 448"
          />
        </svg>
        <p class="text-xs font-bold tracking-wide">SEARCH PLATFORM</p>
      </button>
    </div>
    <div class="order-2 md:order-3 flex space-x-1 w-1/2 md:w-auto">
      <prime-toolbar-button
        iconSrc="assets/icons/icon-favorites.svg"
        label="Favorites"
        route="favorites"
      ></prime-toolbar-button>
      <prime-toolbar-button
        iconSrc="assets/icons/icon-logout.svg"
        label="Log Out"
        route="/logout"
      ></prime-toolbar-button>
    </div>
  </div>
</div>
