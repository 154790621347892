import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnvironmentModel } from '@prime-platform/shared';
import { User } from '@sentry/angular-ivy';

type MyUser = Pick<User, 'id'>;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser$ = new BehaviorSubject<MyUser | null>(null);

  constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

  get currentUser(): Observable<MyUser> {
    return this.currentUser$.asObservable().pipe(
      switchMap(user => {
        if (!user) {
          return this.getMyUser();
        }
        return of(user);
      })
    );
  }


  getMyUser(): Observable<MyUser> {
    return this.http.get<MyUser>(`${this.environment.apiUrl}/user/me`).pipe(
      tap(user => {
        this.currentUser$.next(user);
      })
    );
  }
}
