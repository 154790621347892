import {Component, CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FileService} from '@prime-platform/shared';
import { Image} from '../../../models/image.model';
import {ShareButtonComponent} from '../../share-button/share-button.component';
@Component({
  selector: 'prime-image-modal-sidebar',
  standalone: true,
  imports: [CommonModule, ShareButtonComponent],
  templateUrl: './image-modal-sidebar.component.html',
  styleUrls: ['./image-modal-sidebar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageModalSidebarComponent {
  @Input() image: Image | null = null;

  constructor(private fileService: FileService) {
  }

  download(url: string) {
    const fileName = this.image?.name || '';
    this.fileService.downloadFile(url, fileName)
  }
}
