import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ResourceService } from '../services/resource.service';
import { catchError, of } from 'rxjs';
import { Resource } from '../models/resource.model';

export const resourceResolver: ResolveFn<Resource | null> = (route, state) => {
  const id = route.paramMap.get('resourceId');

  if (id) {
    return inject(ResourceService)
      .getResource(id)
      .pipe(
        catchError((e) => {
          console.log('error', e);
          return of(null);
        })
      );
  } else {
    inject(Router).navigate(['/not-found']);
    return of(null);
  }
};
