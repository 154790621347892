import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Breadcrumb, EnvironmentModel, View} from '@prime-platform/shared';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {


  constructor(private http: HttpClient,
              @Inject('environment') private environment: EnvironmentModel) { }

  getViewBreadcrumbs(id: string): Observable<Partial<View>> {
    return this.http.get<Partial<View>>(`${this.environment.apiUrl}/view/breadcrumbs/${id}`)
  }

  mapToBreadcrumb(view: Partial<View>) {
    const breadcrumbs: Breadcrumb[] = [];
    const mapBreadcrumb = (view: Partial<View>) => {
      if(view.breadcrumbLabel != '') {
        breadcrumbs.unshift({
          label: view.breadcrumbLabel || '',
          route:  view.parent ?`${view.slug}/${view.id}` : `${view.id}`
        })
      }
      if(view.parent) {
        mapBreadcrumb(view.parent)
      }
    }
    mapBreadcrumb(view)
    console.log('breadcrumb service view:', view);
    console.log('breadcrumb service breadcrumbs:', breadcrumbs);
    return breadcrumbs
  }
}
