import {animate, state, style, transition, trigger,} from '@angular/animations';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {CommonModule} from '@angular/common';
import {Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {Image, Reference, Resource, ResourceTypeOption, Document} from '../../models';
import {ImageModalSidebarComponent} from './image-modal-sidebar/image-modal-sidebar.component';
import {ReferenceModalSidebarComponent} from './reference-modal-sidebar/reference-modal-sidebar.component';
import {DocumentModalSidebarComponent} from './document-modal-sidebar/document-modal-sidebar.component';

interface DialogClosedData {
  tag?: string;
  subtag?: string;
}

@Component({
  selector: 'prime-resource-modal',
  standalone: true,
  imports: [
    CommonModule,
    DocumentModalSidebarComponent,
    ReferenceModalSidebarComponent,
    FormsModule,
    NgxExtendedPdfViewerModule,
    ImageModalSidebarComponent,
  ],
  templateUrl: './resource-modal.component.html',
  styleUrls: ['./resource-modal.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  animations: [
    trigger('toggleAnimation', [
      state('false', style({ transform: 'translateX(100%)' })),
      state('true', style({ transform: 'translateX(0)' })),
      transition('* <=> *', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ResourceModalComponent implements OnInit {
  showClean = true;
  reference: Reference | null = null;
  document: Document | null = null;
  image: Image | null = null;
  showSidebar = false;

  constructor(
    public dialogRef: DialogRef<DialogClosedData>,
    @Inject(DIALOG_DATA)
    public data: { resource?: Resource; reference?: Reference }
  ) {}

  ngOnInit() {
    this.reference = this.data.reference || null;
    this.document = this.data.resource?.resourceType === ResourceTypeOption.Document && this.data.resource.document ? this.data.resource.document : null;
    this.image = this.data.resource?.resourceType === ResourceTypeOption.Image && this.data.resource.image ? this.data.resource.image : null;

  }

  get resourceUrl() : string | undefined {
    return this.document ? this.document.fileUrl :
        this.reference && this.showClean ? this.reference.fileUrl :
          this.reference && !this.showClean ? this.reference.annotatedFileUrl :
            '';
  }

  openTag(id: string) {
    this.dialogRef.close({ tag: id });
  }

  openSubtag(id: string) {
    this.dialogRef.close({ subtag: id });
  }
}
