import { inject } from '@angular/core';
import { ResolveFn} from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const loginFallbackResolver: ResolveFn<boolean | null> = () => {
  return inject(AuthService)
    .loginFallback()
    .pipe(
      tap(authUrl => window.location.href = authUrl),
      switchMap(() => of(false))
    )
}
