import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {EnvironmentModel} from '@prime-platform/shared';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuth$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

  get isAuth() {
    return this.isAuth$.asObservable();
  }

  login(): Observable<string> {
    const redirectUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}`);
    return this.http.get<{authUrl: string}>(`${this.environment.apiUrl}/saml/login?redirect=${redirectUrl}`).pipe(
      map(res => res.authUrl)
    );
  }

  loginFallback(): Observable<string> {
    const redirectUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}`);
    return this.http.get<{authUrl: string}>(`${this.environment.apiUrl}/saml/login/fallback?redirect=${redirectUrl}`).pipe(
      map(res => res.authUrl)
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.http.get<{isValid: boolean}>(`${this.environment.apiUrl}/jwt/validate-session`).pipe(
      tap(response => {
        this.isAuth$.next(response.isValid);
      }),
      map(response => response.isValid),
      catchError(() => {
        this.isAuth$.next(false);
        return of(false);
      })
    );
  }
}
