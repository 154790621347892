<div class="flex w-full flex-col justify-between p-2" *ngIf="document">
  <div class="px-3.5 py-3">
    <div
      class="flex md:hidden items-center space-x-3 mb-4"
    >
      <img src="assets/icons/icon-resources.svg" class="w-6 h-6" />
      <p class="leading-none text-rd uppercase text-gray-600">Document</p>
    </div>
    <div
      class="font-light text-xl leading-6 mb-6"
      [innerHTML]="document.title"
    ></div>
    <!--supporting references hidden for now-->
    <!--    <div *ngIf="resource.references.length">-->
    <!--      <div class="flex items-center space-x-2 mb-3">-->
    <!--        <ion-icon name="document" class="w-6 h-6 text-gray-500"></ion-icon>-->
    <!--        <p class="text-sm leading-none text-gray-500 uppercase">-->
    <!--          Supporting references-->
    <!--        </p>-->
    <!--      </div>-->
    <!--      <div class="space-y-2">-->
    <!--        <button-->
    <!--          *ngFor="let reference of resource.references"-->
    <!--          [routerLink]="[-->
    <!--            '',-->
    <!--            { outlets: { modal: ['viewer', 'reference', reference.id] } }-->
    <!--          ]"-->
    <!--          class="button-base flex-col text-left px-3 py-2.5"-->
    <!--        >-->
    <!--          <p-->
    <!--            class="w-56 self-start text-rd leading-none truncate mb-2"-->
    <!--            [innerHTML]="reference.masterVersion.title"-->
    <!--          ></p>-->
    <!--          <p-->
    <!--            class="w-56 self-start text-xs leading-none truncate"-->
    <!--            [innerHTML]="reference.masterVersion.shortCitation"-->
    <!--          ></p>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <div class="flex flex-col space-y-2">
    <prime-share-button></prime-share-button>
    <button
      *ngIf="document.fileUrl"
      class="button-base !text-indigo-600 hover:!text-indigo-500 active:!text-indigo-100 px-4 py-2.5 space-x-2"
      (click)="download(document.fileUrl)"
    >
      <ion-icon name="download-outline" class="w-6 h-6"></ion-icon>
      <p>Download PDF</p>
    </button>
    <button
      *ngIf="document.fileSourceUrl"
      class="button-base !text-pink-600 hover:!text-pink-500 active:!text-pink-100 px-4 py-2.5 space-x-2"
      (click)="download(document.fileSourceUrl)"
    >
      <ion-icon name="download-outline" class="w-6 h-6"></ion-icon>
      <p>Download Source</p>
    </button>
  </div>
</div>
