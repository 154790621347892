import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarButtonComponent } from './toolbar-button/toolbar-button.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { SearchComponent } from '@prime-platform/search';
import { headerButton } from '@prime-platform/shared';

@Component({
  selector: 'prime-header-toolbar',
  standalone: true,
  imports: [CommonModule, ToolbarButtonComponent, DialogModule],
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderToolbarComponent {

  @Input() headerButtonOptions: headerButton[] = [];
  constructor(private dialog: Dialog) {}

  openSearch() {
    this.dialog.open(SearchComponent, {
      width: '80vw',
    });
  }
}
