<div class="flex flex-col w-full h-screen overflow-hidden">
  <prime-header
    [title]="title"
    [titleClass]="titleClass"
    [logoSrc]="logoSrc"
    [logoClass]="logoClass"
    [headerButtonOptions]="headerButtonOptions"
    *ngIf="router.url !== '/logout'"
  ></prime-header>
  <prime-breadcrumbs></prime-breadcrumbs>
  <router-outlet *ngIf="!loading"></router-outlet>
  <router-outlet name="modal"></router-outlet>
  <prime-loading *ngIf="loading"></prime-loading>
</div>



