import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

@Component({
  selector: 'prime-share-button',
  standalone: true,
  imports: [CommonModule, ClipboardModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: ` <div class="relative h-full" [ngClass]="parentStyleClass">
    <button
      class="button-base !text-violet-600 hover:!text-violet-500 active:!text-violet-100 w-full px-4 py-2.5 space-x-2"
      [ngClass]="styleClass"
      [cdkCopyToClipboard]="url"
      (cdkCopyToClipboardCopied)="showCopiedTooltip()"
    >
      <ion-icon name="share-social-outline" class="w-6 h-6"></ion-icon>
      <p>Share</p>
    </button>
    <div
      class="absolute pointer-events-none z-10 py-1 px-3 top-2 right-2 bg-violet-500 text-sm text-white rounded-full shadow-inner transition duration-300"
      [class.opacity-0]="!showTooltip"
    >
      Copied to clipboard!
    </div>
  </div>`,
  styles: [],
})
export class ShareButtonComponent {
  @Input() parentStyleClass = '';
  @Input() styleClass = '';
  @Input() url = window.location.href;
  showTooltip = false;

  showCopiedTooltip() {
    this.showTooltip = true;
    setTimeout(() => (this.showTooltip = false), 2000);
  }
}
