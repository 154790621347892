import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EnvironmentModel, View} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ViewService {

  constructor(private http: HttpClient,
              @Inject('environment') private environment: EnvironmentModel) { }
  getSection(id: string): Observable<View> {
    return this.http.get<View>(`${this.environment.apiUrl}/view/${id}`);
  }
}
