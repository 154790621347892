import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'prime-base-page',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasePageComponent implements AfterViewInit {
  constructor(protected host: ElementRef) {}
  ngAfterViewInit() {
    this.host.nativeElement.scrollTo(0, 0);
  }
}
