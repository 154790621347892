<div class="flex w-full flex-col justify-between p-2" *ngIf="reference">
  <div class="px-3.5 py-3">
    <label
      class="flex lg:hidden items-center justify-center cursor-pointer space-x-3 px-3 py-2 mb-5 bg-gray-550 text-gray-50 shadow-inner rounded-full transition-all"
      for="mobile-toggle"
    >
      <div class="text-sm leading-none uppercase">Clean</div>
      <div class="relative inline-block align-middle">
        <input
          id="mobile-toggle"
          type="checkbox"
          class="hidden"
          [(ngModel)]="showClean"
          (ngModelChange)="showCleanToggle.emit($event)"
        />
        <div
          class="w-11 px-0.5 bg-gradient-to-bl from-gray-700 to-gray-600 rounded-full shadow-inner"
          [ngClass]="{
            '!from-brand-primary !to-brand-secondary': !showClean
          }"
        >
          <div class="relative w-10 h-6"></div>
          <div
            class="absolute w-5 h-5 rounded-full inset-y-0.5 bg-basebg shadow-toggleswitch"
            [@toggleAnimation]="showClean.toString()"
          ></div>
        </div>
      </div>
      <div class="text-sm leading-none uppercase">Highlighted</div>
    </label>

    <div class="flex lg:hidden items-center space-x-3 mb-4">
      <img src="assets/icons/icon-references.svg" class="w-6 h-6" />
      <p class="leading-none text-rd uppercase text-gray-600">Reference</p>
    </div>

    <div
      class="font-light text-xl leading-6 mb-6"
      [innerHTML]="reference.title"
    ></div>

    <p class="text-rd leading-tight text-gray-400 mb-6 flex">
      {{ reference.uniqueID }}.&nbsp;
      <span class="text-gray-600" [innerHTML]="reference.citation"></span>
    </p>

    <div *ngIf="reference.tags && reference.tags.length">
      <div class="flex items-center space-x-2 mb-2">
        <ion-icon
          name="pricetags-outline"
          class="w-6 h-6 text-gray-500"
        ></ion-icon>
        <p class="text-sm leading-none text-gray-500 uppercase">
          Relevant Tags
        </p>
      </div>

      <div>
        <button
          *ngFor="let tag of reference.tags"
          class="tag-base mr-1.5 mb-1.5"
          (click)="onTagClick(tag.id)"
        >
          {{ tag.tag }}
        </button>
        <button
          *ngFor="let subtag of reference.subtags"
          class="subtag-base mr-1.5 mb-1.5"
          (click)="onSubtagClick(subtag.id)"
        >
          {{ subtag.subtag }}
        </button>
      </div>
    </div>
  </div>

  <div class="flex flex-col space-y-2">
    <prime-share-button></prime-share-button>
    <a
      class="button-base !text-indigo-600 hover:!text-indigo-500 active:!text-indigo-100 px-4 py-2.5 space-x-2"
      [href]="reference.fileUrl"
      download="reference.displayName || reference.title"
      target="_self"
    >
      <ion-icon name="download-outline" class="w-6 h-6"></ion-icon>
      <p>Download PDF</p>
    </a>
    <prime-favorite-button
      [isFavorite]="isFavourite"
      (favoriteToggled)="toggleFavourites($event, reference.id)"
    ></prime-favorite-button>
  </div>
</div>
