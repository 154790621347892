import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {EnvironmentModel} from '../models/environment.model';
import {map, Observable, take} from 'rxjs';


interface PresignedUrlResponse {
  body: {
    signed_url : string
  }
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient,
              @Inject('environment') private environment: EnvironmentModel) { }

  getFile(id: string) {
    return this.http.get<PresignedUrlResponse>(`${this.environment.apiUrl}/s3Object/${id}` ).pipe(
      map(res => res.body.signed_url)
    )
  }

  downloadFile(url: string, fileName: string) {
    this.http.get(url, { responseType: 'blob' }).pipe(
      take(1)
    ).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    });
  }

}
