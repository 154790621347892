<div class="flex w-full h-full items-stretch justify-center">
  <div
    class="flex flex-col grow min-h-[0px] md:rounded-lg bg-gray-150 shadow-xl transition-all overflow-hidden"
  >
    <div
      class="flex h-12 shrink-0 items-stretch justify-between bg-gradient-to-t from-gray-100 to-gray-50"
    >
      <div
        class="flex items-center space-x-3 px-4 pt-0.5"
      >
        <ion-icon
          name="alert-circle-outline"
          class="text-brand-primary w-6 h-6 shrink-0"
        ></ion-icon>
        <p class="leading-none text-rd uppercase text-gray-600">
          {{data.title}}
        </p>
      </div>

      <button
        type="button"
        class="button-reset flex items-center px-2 pt-0.5 text-gray-700 hover:bg-white hover:text-brand-primary hover:shadow-leftside transition-all active:bg-gray-600 active:text-white active:shadow-inner"
        (click)="dialogRef.close(true)"
        aria-label="Close"
      >
        <p class="text-rd px-2">CLOSE</p>
        <ion-icon name="close-outline" class="text-4xl"></ion-icon>
      </button>
    </div>

    <div
      class="w-full h-0.5 shrink-0 bg-gradient-to-r from-brand-primary to-brand-secondary"
    ></div>
    <div class="p-5">
      <div [innerHTML]="data.body"></div>
    </div>
  </div>
</div>
