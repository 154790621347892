<div class="">
  <div class="container text-center md:text-left">
    <h1 class="text-6xl md:text-7xl font-thin text-transparent bg-clip-text bg-gradient-to-tr from-brand-primary to-brand-secondary mb-4 md:mb-8">404</h1>

    <h2 class="text-xl md:text-3xl font-light max-w-sm mb-6 md:mb-12">We’re sorry, we can’t find the page you are looking for.</h2>

    <button
      routerLink="/"
      class="button-base w-auto h-full space-x-3 px-2 md:px-4 py-2 sm:py-2.5 mx-auto md:mx-0"
    >
      <img src="assets/icons/icon-home.svg" alt="Home" class="w-5 h-5 mx-auto sm:mx-0" />
      <p class="text-sm font-bold uppercase">Return Home</p>
    </button>
  </div>
</div>