import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'prime-favorite-button',
  standalone: true,
  imports: [CommonModule],
  template: `
    <button
      *ngIf="!isFavorite"
      [ngClass]="styleClass"
      class="button-base !text-rose-600 hover:!text-rose-500 active:!text-rose-100 w-full px-4 py-2.5 space-x-2"
      (click)="addToFavourites()"
    >
      <ion-icon name="heart-outline" class="w-6 h-6"></ion-icon>
      <p>Favorite</p>
    </button>

    <button
      *ngIf="isFavorite"
      class="button-base !text-rose-600 hover:!text-rose-500 active:!text-rose-100 w-full px-4 py-2.5 space-x-2"
      [ngClass]="styleClass"
      (click)="removeFromFavourites()"
    >
      <ion-icon name="heart" class="w-6 h-6"></ion-icon>
      <p>Unfavorite</p>
    </button>
  `,
  styles: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FavoriteButtonComponent {
  @Input() isFavorite = false;
  @Input() styleClass = '';
  @Output() favoriteToggled = new EventEmitter();
  isFavourite$ = new BehaviorSubject(false);

  addToFavourites() {
    this.favoriteToggled.next(true);
  }

  removeFromFavourites() {
    this.favoriteToggled.next(false);
  }
}
