import {Component, CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'prime-error',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div>
      <h4 class="flex items-center mb-3 text-red-600">
        <ion-icon name="alert-circle-outline" size="large" class="mr-2"></ion-icon> Error occurred
      </h4>
      <p>{{errMsg}}</p>
    </div>`,
  styles: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ErrorComponent {
  @Input() errMsg = '';
}
