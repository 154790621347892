import {
  catchError,
  map,
  Observable,
  of,
  OperatorFunction,
  scan,
  startWith,
  switchMap,
} from 'rxjs';
import { RequestState } from '../models';

export function mapToRequestState<T>(
  observable: Observable<T>
): Observable<RequestState<T>> {
  return observable.pipe(
    map((data) => ({ data, loading: false })),
    catchError((error) => of({ error, loading: false })),
    startWith({ error: null, loading: true }),
    scan((state: RequestState<T>, change: RequestState<T>) => ({
      ...state,
      ...change,
    }))
  );
}
