import {Dialog} from '@angular/cdk/dialog';
import {Component, Input, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {Alert, AlertService, headerButton} from '@prime-platform/shared';
import {map, Subscription, take} from 'rxjs';
import {PlatformAlertComponent} from '../platform-alert/platform-alert.component';

@Component({
  selector: 'prime-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @Input() title = '';
  @Input() titleClass = '';
  @Input() logoSrc = '';
  @Input() logoClass = '';
  @Input() headerButtonOptions: headerButton[] = [];
  loading = false;
  alerts: Alert[] = [];
  sub = new Subscription();
  constructor(public router: Router,
              private alertService: AlertService,
              public dialog: Dialog) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      }
      if (
        ev instanceof NavigationEnd ||
        ev instanceof NavigationCancel ||
        ev instanceof NavigationError
      ) {
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    this.checkForAlerts()
  }

  checkForAlerts() {
    this.alertService.getUnReadAlerts().pipe(
      map(alerts => alerts.filter(a => !a.dismissed)),
      take(1)
    ).subscribe(res => {
      this.alerts = res;
      this.alerts.forEach(alert => {
        this.openAlert(alert)
      })
    })
  }

  openAlert(alert: Alert) {
    const dialogRef = this.dialog.open(PlatformAlertComponent, {
      width: '600px',
      panelClass: 'platform-alert',
      data: alert
    });

    this.sub.add(
      dialogRef.closed.subscribe(res => {
        this.alertService.markAsDismissed(alert.id).pipe(take(1)).subscribe()
      })
    )
  }
}
