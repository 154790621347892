// search.service.ts
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SearchResult } from '../models/search-result.model';
import { SearchSortOption } from '../models/search-sort-option.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentModel, OrderBy } from '@prime-platform/shared';
import { catchError, map } from 'rxjs/operators';
import { Breadcrumb } from '@prime-platform/shared';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment: EnvironmentModel
  ) {}

  /**
   * Performs a search query against the API.
   *
   * @param term - The search term.
   * @param filters - An array of filters to apply.
   * @param sortOption - The sort option, including value and order.
   * @returns An Observable of SearchResult array.
   */
  search(
    term: string,
    filters: string[],
    sortOption: SearchSortOption
  ): Observable<SearchResult[]> {
    let params = new HttpParams().set('q', term);

    if (filters && filters.length > 0) {
      params = params.set('filters', filters.join(','));
    }

    if (sortOption) {
      params = params
        .set('sort', sortOption.value)
        .set('order', sortOption.order);
    }

    return this.http
      .get<any[]>(`${this.environment.apiUrl}/search`, { params })
      .pipe(
        map((data) => this.transformData(data)),
        catchError((error) => {
          console.error('Search API Error:', error);
          return throwError(() => new Error('Failed to fetch search results.'));
        })
      );
  }

  /**
   * Transforms raw API data into SearchResult objects.
   *
   * @param data - Raw data from API.
   * @returns Array of SearchResult.
   */
  private transformData(data: any[]): SearchResult[] {
    return data.map((item) => ({
      id: item.id,
      title: item.title,
      type: item.type.toLowerCase(),
      icon: item.icon,
      publishedDate: item.publishedDate,
      uniqueId: item.uniqueId,
      citation: item.citation,
      breadCrumbs: this.transformBreadcrumbs(
        item.breadCrumb,
        item.slug,
        item.id
      ),
      matchCount: item.matchCount,
      route: this.generateRoute(item),
      body: item.body || '',
    }));
  }

  /**
   * Transforms an array of breadcrumb labels into Breadcrumb objects.
   *
   * @param breadCrumbArray - Array of breadcrumb labels.
   * @returns Array of Breadcrumb objects.
   */
  private transformBreadcrumbs(
    breadCrumbArray: string[],
    slug: string,
    id: string
  ): Breadcrumb[] {
    return breadCrumbArray.map((label) => ({
      label: label.replace(/\s+/g, '-'),
      route: this.generateBreadcrumbRoute(slug, id),
    }));
  }

  /**
   * Generates the route for a search result item.
   *
   * @param item - The search result item.
   * @returns The route string.
   */
  private generateRoute(item: any): string {
    return `/${item.type.toLowerCase()}/${item.id}`;
  }

  /**
   * Generates the route for a breadcrumb label.
   *
   * @param label - The breadcrumb label.
   * @returns The route string.
   */
  private generateBreadcrumbRoute(slug: string, id: string): string {
    return `/${slug}/${id}`;
  }

  /**
   * Applies additional filters on the frontend
   * @param data - Array of SearchResult.
   * @param searchTerm - The search term.
   * @param selectedFilters - Array of selected filters.
   * @param selectedSortOption - The sort option.
   * @returns Filtered array of SearchResult.
   */
  private applyFilters(
    data: SearchResult[],
    searchTerm: string,
    selectedFilters: string[],
    selectedSortOption: { value: string; order: string }
  ): SearchResult[] {
    let filteredData = data;

    if (selectedFilters.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedFilters.includes(item.type)
      );
    }

    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.title.toLowerCase().includes(lowerCaseSearchTerm) ||
          (item.citation &&
            item.citation.toLowerCase().includes(lowerCaseSearchTerm))
      );
    }

    return filteredData;
  }
}
