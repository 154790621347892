import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentModel, Favorite } from '../models';
import { switchMap } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    @Inject('environment') private environment: EnvironmentModel
  ) {}

  getFavorites() {
    return this.userService.currentUser.pipe(
      switchMap((user) => {
        return this.http.get<Favorite[]>(
          `${this.environment.apiUrl}/favorite/user/${user.id}`
        );
      })
    );
  }

  addToFavorites(referenceId: string) {
    return this.userService.currentUser.pipe(
      switchMap((user) => {
        return this.http.post<Favorite>(`${this.environment.apiUrl}/favorite`, {
          referenceId,
          userId: user.id,
        });
      })
    );
  }

  removeFormFavorites(favoriteId: string) {
    return this.http.delete<Favorite>(
      `${this.environment.apiUrl}/favorite/${favoriteId}`
    );
  }
}
