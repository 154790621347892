import {Component, Inject} from '@angular/core';
import {EnvironmentModel} from '@prime-platform/shared';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'prime-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent {
  route = `${this.environment.apiUrl}/saml/login`;
  constructor(@Inject('environment') private environment: EnvironmentModel,) {
  }
}
