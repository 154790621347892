export interface Icon {
  id: number;
  iconClass?: string;
  iconType: IconType;
  iconSrc?: string;
  iconTargetCount?: number

}

export enum IconType {
  Icon = 'ICON',
  Count = 'COUNT',
}
