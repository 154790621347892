<div *ngIf="bucket; else notFound" class="">
  <div class="container">
    <h1 class="page-title mb-4 lg:mb-8">{{ bucket.title }}</h1>
    <p class="page-description mb-4 lg:mb-8">{{ bucket.description }}</p>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div
        *ngFor="let section of bucket.children"
        class="col-span-2 md:col-span-1"
      >
        <prime-section-tile
          [title]="section.title"
          [route]="getRoute(section)"
          [disabled]="section.disabled"
          [buttonTitle]="'View section'"
        ></prime-section-tile>
      </div>
    </div>
  </div>
</div>
<ng-template #notFound>
  <p>Bucket not found</p>
</ng-template>
