import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderToolbarComponent } from '../header-toolbar/header-toolbar.component';
import { RouterLink } from '@angular/router';
import { headerButton } from '@prime-platform/shared';

@Component({
  selector: 'prime-header',
  standalone: true,
  imports: [CommonModule, HeaderToolbarComponent, RouterLink],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title = '';
  @Input() titleClass = '';
  @Input() logoSrc = '';
  @Input() logoClass = '';
  @Input() headerButtonOptions: headerButton[] = [];
}
