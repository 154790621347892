import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {Component, CUSTOM_ELEMENTS_SCHEMA, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {Alert} from '@prime-platform/shared';

@Component({
  selector: 'prime-platform-alert',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './platform-alert.component.html',
  styleUrls: ['./platform-alert.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlatformAlertComponent {
  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA)
    public data: Alert
  ) {}
}
