import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { SearchResult } from '../../models/search-result.model';
import { SearchService } from '../../services/search.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchResultComponent } from '../search-result/search-result.component';
import { Router } from '@angular/router';
import { SearchFilter } from '../../models/search-filter.model';
import { SearchSortOption } from '../../models/search-sort-option.model';
import {
  ErrorComponent,
  LoadingComponent,
  mapToRequestState,
  OrderBy,
  RequestState,
} from '@prime-platform/shared';

@Component({
  selector: 'prime-search',
  standalone: true,
  imports: [
    CommonModule,
    SearchFilterComponent,
    ReactiveFormsModule,
    SearchResultComponent,
    FormsModule,
    LoadingComponent,
    ErrorComponent,
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchComponent implements OnInit, OnDestroy {
  filters: SearchFilter[] = [
    { label: 'statements', value: 'statement', selected: false },
    { label: 'references', value: 'reference', selected: false },
    { label: 'resources', value: 'resource', selected: false },
    { label: 'congresses', value: 'congress', selected: false },
  ];
  sortOptions: SearchSortOption[] = [
    { label: 'Most relevant', value: 'matchCount', order: OrderBy.desc },
    {
      label: 'Recently published',
      value: 'publishedDate',
      order: OrderBy.desc,
    },
  ];
  results$!: Observable<RequestState<SearchResult[]>>;
  searchTerm = new FormControl('', { nonNullable: true });
  selectedFilters: string[] = [];
  selectedSortOption!: SearchSortOption;
  sub = new Subscription();

  constructor(
    public dialogRef: DialogRef<string>,
    private searchService: SearchService,
    private router: Router
  ) {}

  ngOnInit() {
    this.sub.add(
      this.searchTerm.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          switchMap(() => this.search())
        )
        .subscribe()
    );
    this.selectedSortOption = this.sortOptions[0];
  }

  updateSelectedFilters(filters: string[]) {
    this.selectedFilters = [...filters];
    this.search();
  }

  search(): Observable<RequestState<SearchResult[]>> {
    this.results$ = mapToRequestState(
      this.searchService.search(
        this.searchTerm.value,
        this.selectedFilters,
        this.selectedSortOption
      )
    );
    return this.results$;
  }

  goToResult(result: SearchResult) {
    if (result.type === 'resource' || result.type === 'reference') {
      this.router.navigate([
        '',
        { outlets: { modal: ['viewer', result.type, result.id] } },
      ]);
    } else {
      this.router.navigate([
        result.breadCrumbs[0].label.toLowerCase() +
          result.breadCrumbs[result.breadCrumbs.length - 1].route,
      ]);
      this.dialogRef.close();
    }
  }

  goToSection(route: string) {
    this.dialogRef.close();
    this.router.navigate([route]);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
