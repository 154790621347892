import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResult } from '../../models/search-result.model';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'prime-search-result',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {
  @Input() result: SearchResult | null = null;
  @Output() resultClicked = new EventEmitter<void>();
  @Output() breadCrumbClicked = new EventEmitter<string>();

  onResultClicked() {
    this.resultClicked.next();
  }
  onBreadCrumbClicked(route: string) {
    this.breadCrumbClicked.next(route);
  }
}
