<div class="relative z-10">
  <div
    class="fixed inset-0 bg-gradient-radial from-black/0 from-10% to-black/50 transition-opacity"
  ></div>
  <div class="fixed inset-0 md:inset-1/25">
    <div class="flex w-full h-full items-stretch justify-center">
      <div
        class="flex flex-col grow min-h-[0px] md:rounded-lg bg-gray-150 shadow-xl transition-all overflow-hidden"
      >
        <div
          class="flex h-12 shrink-0 items-stretch justify-between bg-gradient-to-t from-gray-100 to-gray-50"
        >
          <div class="flex items-center space-x-2 px-4 pt-0.5">
            <img src="assets/icons/icon-search.svg" class="w-6 h-6" />
            <p class="leading-none text-rd uppercase text-gray-600">Search</p>
          </div>
          <button
            type="button"
            class="button-reset flex items-center px-2 pt-0.5 text-gray-700 hover:bg-white hover:text-brand-primary hover:shadow-leftside transition-all active:bg-gray-600 active:text-white active:shadow-inner"
            (click)="dialogRef.close()"
            aria-label="Close"
          >
            <p class="text-rd px-2">CLOSE</p>
            <ion-icon name="close-outline" class="text-4xl"></ion-icon>
          </button>
        </div>

        <div
          class="w-full h-0.5 shrink-0 bg-gradient-to-r from-brand-primary to-brand-secondary"
        ></div>

        <div class="relative w-full p-3 md:p-6 md:pb-3">
          <input
            type="text"
            placeholder="SEARCH"
            [formControl]="searchTerm"
            class="w-full bg-gradient-to-t from-gray-50 to-gray-100 border-b-2 border-gray-200 rounded-lg font-light text-xl text-gray-750 placeholder:text-xl placeholder:font-thin placeholder:tracking-wide py-4 px-14 focus:outline-none"
          />
          <span
            class="absolute inset-y-0 md:top-2.5 p-4 flex items-center pointer-events-none"
          >
            <ion-icon
              name="search-outline"
              class="text-gray-400 text-2xl"
            ></ion-icon>
          </span>
        </div>

        <div class="flex flex-col lg:flex-row grow min-h-[0px] overflow-hidden">
          <div class="w-full lg:w-64 lg:shrink-0 px-3 md:px-6 mb-4 lg:mb-0">
            <prime-search-filter
              [filters]="filters"
              (selectedFiltersChange)="updateSelectedFilters($event)"
            ></prime-search-filter>
          </div>

          <div
            class="relative flex grow min-h-[0px] overflow-hidden pl-2 md:pl-4 lg:pl-0"
          >
            <div
              class="absolute left-2 md:left-4 lg:left-0 right-2 md:right-4 h-auto flex flex-col"
            >
              <div
                class="w-full flex items-end justify-between px-1 md:px-2 pb-2 bg-gray-150 z-50"
              >
                <h6 class="text-sm uppercase leading-none text-gray-700">
                  Showing
                  <span class="text-brand-primary">{{
                    (results$ | async)?.data?.length || 0
                  }}</span>
                  results
                </h6>

                <div class="relative">
                  <div
                    class="absolute h-full top-0 bottom-0 right-4 flex items-center"
                  >
                    <ion-icon
                      name="caret-down-outline"
                      class="text-gray-600"
                    ></ion-icon>
                  </div>
                  <select
                    [(ngModel)]="selectedSortOption"
                    (ngModelChange)="search()"
                    class="dropdown-base px-5 pr-12 pt-2.5 pb-2"
                  >
                    <option
                      *ngFor="let option of sortOptions"
                      [ngValue]="option"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="w-full h-3 bg-gradient-to-b from-gray-150/100 via-gray-150/75 to-gray-150/0"
              ></div>
            </div>
            <div class="grow min-h-[0px] overflow-y-auto">
              <ng-container *ngIf="results$ | async as resultsRequest">
                <prime-loading *ngIf="resultsRequest.loading"></prime-loading>
                <prime-error
                  *ngIf="resultsRequest.error"
                  [errMsg]="'Error searching: ' + resultsRequest.error"
                ></prime-error>

                <div
                  *ngIf="resultsRequest.data as results"
                  class="pt-12 pr-3 md:pr-4 pb-4 pl-1 md:pl-2"
                >
                  <div *ngIf="results.length === 0">No results found.</div>
                  <div *ngFor="let result of results">
                    <prime-search-result
                      [result]="result"
                      (resultClicked)="goToResult(result)"
                      (breadCrumbClicked)="goToSection($event)"
                    ></prime-search-result>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
