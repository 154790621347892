<div class="flex w-full flex-col justify-between p-2" *ngIf="image">
  <div class="px-3.5 py-3">
      <div
      class="font-light text-xl leading-6 mb-6"
    >{{image.name}}</div>
    <p>{{image.caption}}</p>
  </div>

  <div class="flex flex-col space-y-2">
    <prime-share-button></prime-share-button>
    <button
      *ngIf="image.fileUrl"
      class="button-base !text-indigo-600 hover:!text-indigo-500 active:!text-indigo-100 px-4 py-2.5 space-x-2"
      (click)="download(image.fileUrl)"
    >
      <ion-icon name="download-outline" class="w-6 h-6"></ion-icon>
      <p>Download</p>
    </button>
  </div>
</div>

