<nav
  *ngIf="showBreadcrumbs && router.url !== '/logout'"
  class="mx-2 py-1.5 flex items-center bg-gray-125 rounded-t-md shadow-subtle"
>
  <a
    routerLink="/dashboard"
    class="flex items-center h-9.5 bg-gray-50 shadow-breadcrumb rounded-r-full px-6 pl-4 space-x-4 shrink-0 z-10"
  >
    <img src="assets/icons/icon-home.svg" alt="HOME" class="h-5 w-auto shrink-0" />
    <p class="text-sm font-bold uppercase text-brand-primary shrink-0">Home</p>
  </a>

  <!-- Hiding for now, can revist based on feedback -->
  <!-- <a
    (click)="goBack()"
    href="javascript:void(0);"
    class="flex md:hidden items-center h-9.5 bg-gray-50 shadow-breadcrumb rounded-r-full px-6 pl-4 space-x-4 shrink-0 z-10"
  >
    <img src="assets/icons/icon-back.svg" alt="HOME" class="h-5.5 w-5.5 shrink-0" />
    <p class="text-sm font-bold uppercase text-brand-primary shrink-0">Back</p>
  </a> -->

  <ng-container
    *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last"
  >
    <ng-container *ngIf="!last; else lastBreadcrumb">
      <a
        [routerLink]="breadcrumb.route" [relativeTo]="route.firstChild"
        class="hidden md:flex items-center h-9.5 bg-gray-50 shadow-breadcrumb rounded-r-full px-6 pl-10 -ml-6"
        [ngStyle]="{ 'z-index': 9 - i }"
      >
        <p class="text-sm font-bold uppercase text-brand-accent">
          {{ breadcrumb.label }}
        </p>
      </a>
    </ng-container>
    <ng-template #lastBreadcrumb>
      <p class="text-sm font-bold uppercase text-gray-700 px-3">
        {{ breadcrumb.label }}
      </p>
    </ng-template>
  </ng-container>
</nav>
