export interface ReferenceSearchQuery {
  searchText: string;
  tags: string[];
  subTags: string[];
  sort: Sort;
}

export enum OrderBy {
  asc,
  desc,
}

export interface Sort {
  order: OrderBy;
  key: string;
}

export const defaultSort: Sort = {
  order: OrderBy.asc,
  key: 'citation',
};
