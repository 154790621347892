import {Icon} from './icon.model';
import {View} from './view.model';

export interface Button {
  id: string,
  disabled: boolean,
  disabledLabel?: string,
  label: string,
  icon: Icon,
  target: TargetOption;
  targetDocumentId?: string;
  targetExternalLink?: string;
  targetReferenceId?: string;
  targetResourceId?: string;
  targetViewSlug?: string;
  targetViewId: string;
  targetView?: View;
}

export enum TargetOption {
  Document = 'DOCUMENT',
  ExternalLink = 'EXTERNAL_LINK',
  Reference = 'REFERENCE',
  Resource = 'RESOURCE',
  View = 'VIEW'
}
