export interface Tag {
  id: string;
  tag: string;
  subtags: SubTag[];
  selected?: boolean;
}

export interface SubTag {
  id: string;
  subtag: string;
  selected?: boolean;
}
