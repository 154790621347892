import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceModalComponent } from '../resource-modal/resource-modal.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Reference, Resource } from '../../models';

@Component({
  selector: 'prime-viewer',
  standalone: true,
  imports: [CommonModule, ResourceModalComponent, DialogModule, RouterOutlet],
  template: ``,

  styles: [],
})
export class ViewerComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: Dialog
  ) {}
  ngOnInit() {
    const referenceSubject = this.route.snapshot.data['reference'];
    const resource = this.route.snapshot.data['resource'];

    if (referenceSubject instanceof BehaviorSubject) {
      this.sub.add(
        referenceSubject.subscribe((reference) => {
          if (reference) {
            this.openModalWithReference(reference);
          } else {
            console.warn('No reference data available yet.');
          }
        })
      );
    } else if (resource) {
      this.openModalWithResource(resource);
    }
  }
  openModalWithReference(reference: Reference) {
    const modal = this.dialog.open(ResourceModalComponent, {
      data: { reference },
      width: '80vw',
    });
    this.sub.add(
      modal.closed.subscribe((val) => {
        if (val) {
          this.dialog.closeAll();
          this.router.navigate(
            [{ outlets: { modal: null, primary: 'references' } }],
            { queryParams: val, queryParamsHandling: 'merge' }
          );
        } else {
          this.router.navigate([{ outlets: { modal: null } }]);
        }
      })
    );
  }

  openModalWithResource(resource: Resource) {
    const modal = this.dialog.open(ResourceModalComponent, {
      data: { resource },
      width: '80vw',
    });
    this.sub.add(
      modal.closed.subscribe(() =>
        this.router.navigate([{ outlets: { modal: null } }])
      )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
