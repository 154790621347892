import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, switchMap, tap} from 'rxjs';
import {EnvironmentModel, Reference} from '../models';
import {OrderBy, ReferenceSearchQuery,} from '../models/reference-search-query.model';
import {FavoriteService} from './favorite.service';


@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  public references$ = new BehaviorSubject<Reference[]>([]);

  constructor(private http: HttpClient,
              private favoriteService: FavoriteService,
              @Inject('environment') private environment: EnvironmentModel) { }


  get references(): Observable<Reference[]> {
    if(!this.references$.getValue().length){
      return this.getReferences();
    }
    return this.references$.asObservable();
  }

  getReferences(): Observable<Reference[]> {
    return this.http.get<Reference[]>(`${this.environment.apiUrl}/reference`).pipe(
      tap(res => this.references$.next(res))
    )
  }

  getFilteredReferences(query: ReferenceSearchQuery): Observable<Reference[]> {
    return this.references.pipe(
      map((references) => {
        return this.filterReferences(references, query);
      })
    );
  }

  filterReferences(
    references: Reference[],
    query: ReferenceSearchQuery
  ): Reference[] {
    let filteredReferences: Reference[] = [...references];

    if (query.searchText) {
      const searchTextLower = query.searchText.toLowerCase();
      filteredReferences = filteredReferences.filter(
        (reference) =>
          reference.title
            .toLowerCase()
            .includes(searchTextLower) ||
          reference.citation
            .toLowerCase()
            .includes(searchTextLower)
      );
    }

    if (query.tags.length > 0) {
      filteredReferences = filteredReferences.filter((reference) => {
        const referenceTags = reference.tags.map((t) => t.id);
        return query.tags.some((tag) => referenceTags.includes(tag));
      });
    }

    if (query.subTags.length > 0) {
      filteredReferences = filteredReferences.filter((reference) => {
        const referenceSubtags = reference.subtags.map((t) => t.id);
        return query.subTags.some((tag) => referenceSubtags.includes(tag));
      });
    }

    if (query.sort.key === 'citation') {
      filteredReferences.sort((a, b) =>
        query.sort.order === OrderBy.asc
          ? a.citation
              .toLowerCase()
              .localeCompare(b.citation)
          : b.citation
              .toLowerCase()
              .localeCompare(a.citation)
      );
    } else if (query.sort.key === 'uniqueID') {
      filteredReferences.sort((a, b) =>
        query.sort.order === OrderBy.asc
          ? a.uniqueID - b.uniqueID
          : b.uniqueID - a.uniqueID
      );
    }

    return filteredReferences;
  }

  getReference(id: string): Observable<Reference> {
    return this.http.get<Reference>(`${this.environment.apiUrl}/reference/${id}`).pipe(
      switchMap(reference => this.favoriteService.getFavorites().pipe(
        map(favorites => {
          if(favorites) {
            reference.isFavorite = !!favorites.find(f => f.reference.id === id)
          }
          return reference
        })
      ))
    )
  }
}
