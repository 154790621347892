<div class="p-2">
  <div
    class="w-full sm:h-header flex flex-col justify-between flex-shrink-0 bg-basebg/75 shadow-subtle rounded-md"
  >
    <div
      class="flex flex-grow items-center justify-between px-2 md:px-5 cursor-pointer"
      routerLink="/"
    >
      <p
        class="text-sm sm:text-lg md:text-2xl lg:text-3xl font-thin leading-none py-2"
        [class]="titleClass"
      >
        <span
          class="block sm:inline text-base sm:text-lg md:text-2xl lg:text-3xl leading-none font-bold text-transparent bg-clip-text bg-gradient-to-tr from-brand-primary to-brand-secondary"
          >{{ title }}</span
        >
        Digital Scientific Platform
      </p>
      <img [src]="logoSrc" alt="Brand logo" [class]="logoClass" />
    </div>
    <prime-header-toolbar
     [headerButtonOptions]="headerButtonOptions"
    ></prime-header-toolbar>
  </div>
</div>
