import {Image} from './image.model';
import {Reference} from './reference.model';
import {File, FileSource} from './file.model';

export interface Resource {
  id: string;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
  resourceType: ResourceTypeOption;
  name: string;

  document?: Document;
  documentId?: string;
  externalLink?: ExternalLink;
  externalLinkId?: string;
  image?: Image;
  imageId?: string;
  reference?: Reference;
  referenceId?: string;
}


export interface Document {
  id: string;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
  displayName: string;
  publicationDate: string;
  title: string;
  file?: File;
  fileSource?: FileSource;
  fileUrl?: string;
  fileSourceUrl?: string;
}

interface ExternalLink {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  archived: boolean;
  url: string;
}

export enum ResourceTypeOption {
  Document = 'DOCUMENT',
  ExternalLink = 'EXTERNAL_LINK',
  Image = 'IMAGE',
  Reference = 'REFERENCE',
}




