import {Button, Icon, Reference, Resource} from '@prime-platform/shared';
import {TemplateOption} from './template-options.model';

export interface View {
  id: string;
  title: string;
  body: string;
  description: string;
  breadcrumbLabel: string;
  slug?: string;
  template: TemplateOption;
  children: View[];
  icon?: Icon;
  disabled: boolean;
  buttons: Button[];
  board?: string;
  parent?: View;
  references: Reference[]
  resources: Resource[]
}
