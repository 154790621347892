import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'prime-section-tile',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './section-tile.component.html',
  styleUrls: ['./section-tile.component.scss'],
})
export class SectionTileComponent {
  @Input() title = '';
  @Input() route = '';
  @Input() disabled = false;
  @Input() buttonTitle = '';
}
