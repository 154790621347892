import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentModel } from '../models/environment.model';
import { map, Observable, take, firstValueFrom } from 'rxjs';

interface PresignedUrlResponse {
  body: {
    signed_url: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment: EnvironmentModel
  ) {}

  getFile(id: string): Observable<string> {
    return this.http
      .get<PresignedUrlResponse>(`${this.environment.apiUrl}/s3Object/${id}`)
      .pipe(map((res) => res.body.signed_url));
  }

  async downloadFile(id: string, fileName: string) {
    try {
      const url = await firstValueFrom(this.getFile(id));
      const blob = await firstValueFrom(
        this.http.get(url, { responseType: 'blob' })
      );

      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
}
