import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, of } from 'rxjs';
import { Reference } from '../models';
import { ReferenceService } from '../services/reference.service';

export const referenceResolver: ResolveFn<Reference | null> = (
  route,
  state
) => {
  const id = route.paramMap.get('referenceId');

  if (id) {
    return inject(ReferenceService)
      .getReference(id)
      .pipe(
        catchError((e) => {
          console.log('error', e);
          return of(null);
        })
      );
  } else {
    inject(Router).navigate(['/not-found']);
    return of(null);
  }
};
