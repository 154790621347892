import { File } from  './file.model'
export interface Image {
  id: string;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
  name: string;
  caption?: string;

  file?: File;
  fileId?: string;
  fileUrl?: string;
}

