import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'prime-toolbar-button',
  standalone: true,
  imports: [CommonModule, RouterLink],
  template: ` <button
    [routerLink]="route"
    class="button-base w-full h-full space-x-3 px-2 md:px-4 py-2 sm:py-2.5"
  >
    <img [src]="iconSrc" [alt]="label" class="w-5 h-5 mx-auto sm:mx-0" />
    <p class="hidden sm:inline text-xs font-bold uppercase">{{ label }}</p>
  </button>`,
  styles: [
    `
      :host {
        @apply flex-grow md:flex-grow-0 md:shrink-0;
      }
    `,
  ],
})
export class ToolbarButtonComponent {
  @Input() iconSrc = '';
  @Input() label = '';
  @Input() route = '';
}
