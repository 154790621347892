<div class="relative z-10">
  <div
    class="fixed inset-0 bg-gradient-radial from-black/0 from-10% to-black/50 transition-opacity"
  ></div>
  <div class="fixed inset-0 lg:inset-1/25">
    <div class="flex w-full h-full items-stretch justify-center">
      <div
        class="flex flex-col grow min-h-[0px] lg:rounded-lg bg-gray-150 shadow-xl transition-all overflow-hidden"
      >
        <div
          class="flex h-12 shrink-0 items-stretch justify-between bg-gradient-to-t from-gray-100 to-gray-50"
        >
          <button
            type="button"
            class="button-reset flex lg:hidden items-center px-1 pt-0.5 text-gray-700 hover:bg-white hover:text-brand-primary hover:shadow-leftside transition-all active:bg-gray-600 active:text-white active:shadow-inner"
            aria-label="Details"
            (click)="showSidebar = !showSidebar"
          >
            <button
              class="hamburger hamburger--arrow scale-60 is-active pl-2"
              type="button"
              [class.is-active]="showSidebar"
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
            <p class="text-rd leading-none pl-0.5 pr-2">DETAILS</p>
          </button>
          <div
            *ngIf="reference"
            class="flex items-center space-x-3 px-4 pt-0.5"
          >
            <img src="assets/icons/icon-references.svg" class="w-6 h-6" />
            <p class="leading-none text-rd uppercase text-gray-600">
              Reference
            </p>
          </div>
          <div *ngIf="document" class="flex items-center space-x-2 px-4 pt-0.5">
            <img src="assets/icons/icon-resources.svg" class="w-6 h-6" />
            <p class="leading-none text-rd uppercase text-gray-600">Document</p>
          </div>
          <div *ngIf="image" class="flex items-center space-x-2 px-4 pt-0.5">
            <img src="assets/icons/icon-resources.svg" class="w-6 h-6" />
            <p class="leading-none text-rd uppercase text-gray-600">Image</p>
          </div>
          <!--<label
            *ngIf="reference"
            class="flex items-center cursor-pointer space-x-3 px-4 pt-0.5 text-gray-600 hover:bg-white hover:shadow-bothsides transition-all active:bg-gray-600 active:text-white active:shadow-inner"
            for="toggle"
          >
            <div class="text-rd uppercase">Clean</div>
            <div class="relative inline-block align-middle">
              <input
                id="toggle"
                type="checkbox"
                class="hidden"
                [disabled]="!reference.annotatedFileUrl || !reference.showAnnotatedFile"
                [(ngModel)]="showClean"
              />
              <div
                class="w-11 px-0.5 bg-gradient-to-bl from-gray-600 to-gray-500 rounded-full shadow-inner"
                [ngClass]="{
                        '!from-brand-primary !to-brand-secondary': !showClean
                      }"
              >
                <div class="relative w-10 h-6"></div>
                <div
                  class="absolute w-5 h-5 rounded-full inset-y-0.5 bg-basebg shadow-toggleswitch"
                  [@toggleAnimation]="showClean.toString()"
                ></div>
              </div>
            </div>
            <div class="text-rd uppercase">Highlighted</div>
          </label>-->
          <button
            type="button"
            class="button-reset flex items-center px-2 pt-0.5 text-gray-700 hover:bg-white hover:text-brand-primary hover:shadow-leftside transition-all active:bg-gray-600 active:text-white active:shadow-inner"
            (click)="dialogRef.close()"
            aria-label="Close"
          >
            <p class="text-rd leading-none px-2">CLOSE</p>
            <ion-icon name="close-outline" class="text-4xl"></ion-icon>
          </button>
        </div>

        <div
          class="w-full h-0.5 shrink-0 bg-gradient-to-r from-brand-primary to-brand-secondary"
        ></div>

        <div class="flex flex-row grow min-h-[0px] overflow-hidden">
          <!--          SIDEBARS-->
          <div
            class="absolute lg:static -translate-x-72 lg:translate-x-0 transition-transform duration-500 w-72 h-full shrink-0 z-10 flex flex-col bg-gray-150 border-r border-gray-250 shadow-xl"
            [class.is-open]="showSidebar"
          >
            <prime-document-modal-sidebar
              class="flex flex-grow"
              *ngIf="document"
              [document]="document"
            ></prime-document-modal-sidebar>
            <prime-image-modal-sidebar
              class="flex flex-grow"
              *ngIf="image"
              [image]="image"
            ></prime-image-modal-sidebar>
            <prime-reference-modal-sidebar
              class="flex flex-grow"
              *ngIf="reference"
              [reference]="reference"
              [showClean]="showClean"
              (tagClicked)="openTag($event)"
              (subtagClicked)="openSubtag($event)"
              (showCleanToggle)="showClean = $event"
            ></prime-reference-modal-sidebar>
          </div>
          <!--          // SIDEBARS-->

          <div
            class="relative flex grow min-h-[0px] overflow-hidden pl-2 lg:pl-4 lg:pl-0"
          >
            <div
              class="bg-gray-600 grow min-h-[0px] overflow-y-auto flex justify-center"
            >
              <ngx-extended-pdf-viewer
                *ngIf="resourceUrl"
                class="w-full"
                [src]="resourceUrl"
                [height]="'100%'"
                [theme]="'dark'"
                [useBrowserLocale]="true"
                [textLayer]="true"
              >
              </ngx-extended-pdf-viewer>
              <img
                *ngIf="image"
                [src]="image.fileUrl"
                [alt]="image.caption || 'Resource image'"
                class="h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
