import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {EnvironmentModel, Favorite} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  // temporary until we get authentication setup
  userId = '';
  constructor(private http: HttpClient,
              @Inject('environment') private environment: EnvironmentModel) { }

  getFavorites() {
    return this.http.get<Favorite[]>(`${this.environment.apiUrl}/favorite/user/${this.userId}`)
  }

  addToFavorites(referenceId: string) {
    return this.http.post<Favorite>(`${this.environment.apiUrl}/favorite`, {referenceId, userId: this.userId})
  }

  removeFormFavorites(favoriteId: string) {
    return this.http.delete<Favorite>(`${this.environment.apiUrl}/favorite/${favoriteId}`)
  }
}
