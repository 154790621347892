<div class="md:mt-2 lg:mt-5">
  <h6 class="text-sm uppercase text-gray-700 mb-3 lg:mb-5">Filter results</h6>

  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-1 gap-2">
    <div
      *ngFor="let filter of filters; let i = index"
      class="relative button-filter md:mb-2"
      [attr.aria-expanded]="filter.selected"
      [attr.aria-checked]="filter.selected"
    >
      <input
        type="checkbox"
        class="absolute accent-brand-secondary w-4.5 h-4.5 left-4 cursor-pointer"
        [id]="'search-filter-' + i"
        [checked]="filter.selected"
        (change)="toggleFilter(filter)"
      />
      <label [for]="'search-filter-' + i" class="w-full font-medium capitalize pl-12 pr-5 py-3 cursor-pointer">{{
        filter.label
      }}</label>
    </div>
  </div>
</div>
