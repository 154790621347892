import {CommonModule, Location} from '@angular/common';
import {Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit,} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink,} from '@angular/router';
import {Breadcrumb} from '@prime-platform/shared';
import {filter, map, of, Subscription, switchMap} from 'rxjs';
import {BreadcrumbService} from '../services/breadcrumb.service';

@Component({
  selector: 'prime-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[] = [];
  showBreadcrumbs = true;
  sub = new Subscription();
  constructor(
    private breadcrumbService: BreadcrumbService,
    public router: Router,
    public route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.sub.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          switchMap(() => {
              const lastChild = this.getLastChild(this.route.root);

              const view = lastChild.snapshot.data['view'];
              const congress = lastChild.snapshot.data['congress'];

              if (view) {
                return this.breadcrumbService.getViewBreadcrumbs(view.id).pipe(
                  map((res) => {
                    return this.breadcrumbService.mapToBreadcrumb(res);
                  }))
              } else if (congress) {
                return of([
                  {
                    label: 'Congress library',
                    route: `congress-library`
                  },
                  {
                    label: congress.name,
                    route: `congress/${congress.id}`
                  }]);
              } else if (this.router.url.includes('congress')) {
                return of([
                  {
                    label: 'Congress library',
                    route: `congress-library`
                  }])
              } else {
                return of([]);
              }
            }
          )
        )
        .subscribe((breadcrumbs) => {
          this.showBreadcrumbs =
            this.router.routerState.snapshot.url !== '/dashboard';
          this.breadcrumbs = breadcrumbs;
        })
    );
  }
  private getLastChild(route: ActivatedRoute): ActivatedRoute {
    let lastChild = route;
    while (lastChild.firstChild) {
      lastChild = lastChild.firstChild;
    }
    return lastChild;
  }

  goBack() {
    this.location.back()
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
