<div class="flex flex-col gap-3 justify-items-center items-center p-3">
  <p>You are not authenticated, please click login below</p>
  <button
    [routerLink]="route"
    class="button-base space-x-3 px-2 md:px-4 py-2 sm:py-2.5"
  >
    <ion-icon
      name="log-in-outline"
      class="text-brand-primary w-8 h-8 shrink-0"
      size="large"></ion-icon>
    <p class="hidden sm:inline text-xs font-bold uppercase">Login</p>
  </button>
</div>
