import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilter } from '../../models/search-filter.model';

@Component({
  selector: 'prime-search-filter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent {
  @Input() filters: SearchFilter[] = [];
  @Output() selectedFiltersChange = new EventEmitter<string[]>();

  toggleFilter(filter: SearchFilter): void {
    filter.selected = !filter.selected;
    this.emitSelectedFilters();
  }

  emitSelectedFilters(): void {
    const selectedFilterLabels = this.filters
      .filter((filter) => filter.selected)
      .map((filter) => filter.value);

    this.selectedFiltersChange.emit(selectedFilterLabels);
  }
}
