import {CommonModule} from '@angular/common';
import {Component, CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {FileService} from '@prime-platform/shared';
import {ShareButtonComponent} from '../../share-button/share-button.component';
import {Document} from './../../../models';

@Component({
  selector: 'prime-document-modal-sidebar',
  standalone: true,
  imports: [CommonModule, RouterLink, ShareButtonComponent],
  templateUrl: './document-modal-sidebar.component.html',
  styleUrls: ['./document-modal-sidebar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocumentModalSidebarComponent {
  @Input() document: Document | null = null;

  constructor(private fileService: FileService) {
  }

  download(url: string) {
    const fileName = this.document?.displayName || '';
      this.fileService.downloadFile(url, fileName)
    }
}
